.rs-nav-horizontal .rs-nav-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.rs-nav-reversed.rs-nav-horizontal .rs-nav-bar {
  bottom: auto;
  top: 0;
}
.rs-nav-horizontal>.rs-nav-item,
.rs-nav-horizontal>.rs-dropdown {
  display: inline-block;
  vertical-align: top;
}
.rs-nav-vertical>.rs-dropdown {
  width: 100%;
}

.rs-nav-vertical>.rs-dropdown>.rs-dropdown-toggle {
  width: 100%;
  text-align: left;
  z-index: 0;
}

.rs-nav-reversed.rs-nav-vertical .rs-nav-bar {
  right: auto;
}


.rs-nav-tabs .rs-nav-item:hover,
.rs-nav-tabs .rs-nav-item:focus {
  background: #e5e5ea;
  /* background: var(--rs-navs-bg-hover); */
  border: whitesmoke;

}

.rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-navs-tab-border);
  background-color: #fff;
  /* background-color: var(--rs-bg-card); */
  z-index: 1;
}