.chart {
    border: 1px solid #808080;
    border-radius: 0 0 10px 10px;
}

.thead {
    border: 1px solid #808080;
    border-radius: 5px 5px 0 0;
}

.td {
    /* border: 1px solid #808080; */
    /* height: 180px; */

}

.thd {
    border-right: 1px solid #cacccd;
}

.drop-box,
.responsive-box {
    /* border-top: 2px solid #0AA7B8; */
    box-shadow: -6px 4px 5px -4px rgba(39, 50, 51, 0.87);
    -webkit-box-shadow: -6px 4px 5px -4px rgba(39, 50, 51, 0.87);
    -moz-box-shadow: -6px 4px 5px -4px rgba(39, 50, 51, 0.87);
}





@media only screen and (min-width:320px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    /* For mobile  */
    .resp {
        width: 80%;
        margin-left: 18%;
    }

    .navi {
        padding: 0 10px;
    }



}

@media only screen and (min-width:481px) {

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /* For mobile  */
    .resp {
        width: 80%;
        margin-left: 18%;
    }

    .navi {
        padding: 0 10px;
    }



}

@media only screen and (min-width:641px) {

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    .resp {
        width: 88%;
        margin-left: 10%;
    }

    .navi {
        padding: 0 8px;
    }



}

@media only screen and (min-width:961px) and (max-width:1024px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /* For tablets: */
    .resp {
        width: 87%;
        margin-left: 10%;
    }

    .navi {
        padding: 0 8px;
    }


}

@media only screen and (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    /* For desktop: */
    .resp {
        width: 94%;
        margin-left: 5%;
    }

    /* .bar {
        margin: 0 auto;
    } */

}

@media only screen and (min-width:1281px) {

    /* hi-res laptops and desktops */
    .resp {
        width: 94%;
        margin-left: 25%;
    }


}


.resizer {
    display: inline-block;
    background: #0AA7B8;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    /* prevent scroll on touch devices */
    touch-action: none;
}

.isResizing {
    background: #0AA7B8;
}