/* Staff, patient, settings -> sidebar design  */


.setting-nav-child {
    /* border: 1px solid #09A2B3; */
    border-left-width: 2px;
    border-right-width: 2px;
    transition: all 0.3s;
}

.setting-nav {
    border: 0.1px solid #c2c2c28e;
    box-shadow: 0px 0px 16px -1px #adadad;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.setting-nav-child:hover {
    background-color: #02818F;
    color: #fff;
}

.setting-body {
    border: 0.1px solid #c2c2c28e;
}

.item:hover {
    background-color: #02818F;
    color: #fff;

}



.edit-box {
    border-top: 5px solid #0AA7B8;
    border-bottom: 5px solid #0AA7B8;
    transition: all 0.5s;
    box-shadow: 6px 5px 5px -4px rgba(39, 50, 51, 0.87);
    -webkit-box-shadow: 6px 5px 5px -4px rgba(39, 50, 51, 0.87);
    -moz-box-shadow: 6px 5px 5px -4px rgba(39, 50, 51, 0.87);
}

.page {
    border: 1px solid #CED4DB;
    border-radius: 5px;
    max-width: 100%;
    text-align: center;
}

.timesheet-time-box {
    border: 1px solid #CED4DB;
    border-radius: 5px;
    width: 50px;
    text-align: center;
}

.tab-box {
    border: 1px solid #adadad;
    background-color: #f9f9f9;
}

.active-tab {
    border-top: 4px solid #09A2B3;
    border-right: 2px solid #adadad;
    border-left: 2px solid #adadad;
    border-bottom: 2px solid #fff;

}

@media (min-width:320px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    /* For mobile  */

    .setting-nav {
        width: 100%;
    }

    .setting-body {
        width: 100%;
    }

}

@media (min-width:481px) {

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /* For mobile  */
    .resp {
        width: 80%;
        margin-left: 18%;
    }

    .navi {
        padding: 0 10px;
    }

    .setting-nav {
        width: 100%;
    }

    .setting-body {
        width: 100%;
    }

}

@media (min-width:641px) {

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    .resp {
        width: 88%;
        margin-left: 10%;
    }

    .navi {
        padding: 0 8px;
    }

    .setting-body {
        width: 100%;
    }

    .data-box {
        width: 100%;
    }

}

@media (min-width:961px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /* For tablets: */
    .resp {
        width: 87%;
        margin-left: 10%;
    }

    .navi {
        padding: 0 8px;
    }

    .setting-body {
        width: 100%;
    }

    .data-box {
        width: 100%;
    }

}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    /* For desktop: */
    .resp {
        width: 94%;
        margin-left: 5%;
    }

    .setting-nav {
        /* width: 18%; */
        width: 20%;
    }

    .setting-body {
        width: 80%;
    }

    .data-box {
        width: 30%;
    }

    /* .bar {
        margin: 0 auto;
    } */
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .resp {
        width: 94%;
        margin-left: 5%;
    }

    .setting-nav {
        /* width: 18%; */
        width: 18%;
    }

    .setting-body {
        width: 82%;
    }

    /* .setting-nav {
        width: 15%;
    }

    .setting-body {
        width: 83%;
    } */

    .data-box {
        width: 30%;
    }

    /*
    .submit-box {
        width: 10%;
    }*/

    .show-box {
        width: 40%;
    }

    .select-box {
        width: 100%;
    }

}