.super-admin-nav {
    transition: 0.1s ease-in;
    color: #EFF7F8;
}

.super-admin-nav:hover {
    border-bottom: 1px solid white;
    color: white;
    transition: 0.5s;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #f7f7f7 !important;
}

.table-striped-rows thead {
    background-color: white !important;
}

.s-navbar {
    box-shadow: 0px 4px 5px -1px rgba(36, 36, 36, 0.85);
}

.s-main {
    box-shadow: 0px 3px 5px -1px #bababa;
}


/* //sidebar  */

#sidebar {
    z-index: 10;
    height: 100%;
    width: 60px;
    background: #0AA7B8;
    border-right: 1px solid #ddd;
    transition: all .3s ease-in-out;
    overflow: scroll;
}

#sidebar:hover {
    width: 360px;
}

#sidebar a {
    text-decoration: none;
    display: block;
    padding: 20px 20px;
    color: #000000;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial;
    width: 30px;
    white-space: nowrap;
    transition: all .2s ease-in-out;
}

#sidebar a span {
    opacity: 0;
    transition: all .2s ease-in-out;
}

#sidebar:hover a span {
    opacity: 0.2
}

#sidebar a:hover span {
    opacity: 1;
}

#sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: black !important;
}

#sidebar a:hover,
#sidebar a.active {
    text-decoration: none;
    background: #4caf50;
    color: black;
    width: "full";
}

#sidebar a.active {
    background: #00ff00;
}