/* @import 'antd/dist/antd.css'; */
/* @import "./customAntd.css"; */
@import "./Antd-v5.css";
@import "./customToastify.css";


* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  line-height: inherit !important;
}

main {
  width: 100%;
  padding: 16px;

}



.superAdmin-sidebar {
  /* box-shadow: -1px 0px 4px 3px rgba(0, 0, 0, 0.85); */
}

.s-top-section {
  display: flex;
  height: 80px;
  /* background-color: #b91c1c; */
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 10px;
}



.top-section {
  display: flex;
  height: 80px;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  /* font-size: 30px; */
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 30px;
}

.link {
  /* display: flex; */
  color: #fff;
  padding: 10px 16px;
  gap: 15px;
  transition: all 0.5s;
  /* justify-items: center; */
  align-items: center;
  text-decoration: none;
}

.link_text {
  font-weight: 500;
}

/* .icon {
  font-size: 25px;
  padding: 2px;
} */

.link:hover {
  background: #02818F;
  color: #fff;
  transition: all 0.5s;
  /* margin: 5px;
  border-radius: 10px; */

}

.active_sidebar {
  /* background: #fff !important; */
  color: #02818F !important;
}

input[type='checkbox'] {
  accent-color: teal;
}

/* ----------------------------------------------re-used custom classes ----------------------------- */
.schedule-go-btn {
  background-color: #38B3C6;
  padding: 0 5px;
  border-radius: 3px;
  margin-top: 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: white;
}

.pms-button {
  padding-left: 11px;
  padding-right: 8px;
  padding-top: 7px;
  padding-bottom: 4px;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'poppins', sans-serif;
  font-size: 12px;
  line-height: 17.6px !important;
  /* letter-spacing: 1px; */
  word-spacing: 0.2px;
  background-color: #0aa7b8;
  background-clip: border-box;
  color: rgb(255, 255, 255);
  border-radius: 3px;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  display: inline-block;
  transition: ease-in-out 0.3s;
  border: 1px solid #0AA7B8;
}

.pms-button:hover {
  background-image: linear-gradient(141deg, #02818F 0%, #0aa7b8 100%);
  color: rgb(255, 255, 255);
}

.pms-close-button {
  padding-left: 11px;
  padding-right: 8px;
  padding-top: 7px;
  padding-bottom: 4px;
  font-family: 'Roboto',
    sans-serif;
  font-size: 12px;
  line-height: 17.6px !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  word-spacing: 0.2px;
  background-color: #b91c1c;
  background-clip: border-box;
  color: rgb(255, 255, 255);
  border-radius: 3px;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  display: inline-block;
  transition: ease-in-out 0.3s;
  border: 0.5px solid #b91c1c;
}

.pms-close-button:hover {
  background-image: linear-gradient(141deg, #b91c1c 0%, #f87171 100%);

}

.pms-clear-button {
  padding: 6px 4px 4px;
  /* padding-left: 11px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 4px; */
  font-family: 'poppins',
    sans-serif;
  font-size: 12px;
  line-height: 17.6px !important;
  text-transform: capitalize;
  /* letter-spacing: 1px; */
  word-spacing: 0.2px;
  background-clip: border-box;
  color: #0aa7b8;
  border-radius: 5px;
  border: 0.5px solid #c1c1c1;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  display: inline-block;
  transition: ease-in-out 0.1s;
}

.pms-clear-button:hover {
  background-image: linear-gradient(141deg, #b91c1c 0%, #b91c1c 100%);
  color: #fff;
  /* border: 0.5px solid #b91c1c; */
}

.pms-input-button {
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.2px;
  padding: 4px 12px;
  background-color: #0aa7b8;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  border-radius: 3px;
}

.pms-all-select-button {
  padding-left: 11px;
  padding-right: 8px;
  padding-top: 1px;
  /* padding-bottom: 2px; */
  font-family: 'Roboto',
    sans-serif;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
  word-spacing: 0.2px;
  background-color: #0aa7b8;
  background-clip: border-box;
  color: rgb(255, 255, 255);
  border-radius: 3px;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  display: inline-block;
  transition: ease-in-out 0.3s;
  border: 1px solid #0AA7B8;
}

.pms-all-select-button:hover {
  background-image: linear-gradient(141deg, #02818F 0%, #0aa7b8 100%);
}

.pms-input-button:hover {
  background-image: linear-gradient(141deg, #02818F 0%, #0aa7b8 100%);
}

.pms-white-button {
  padding: 5px 5px 4px;
  font-family: poppins,
    sans-serif;
  font-size: 14px;
  line-height: 17.6px !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  word-spacing: 0.2px;
  color: #0aa7b8;
  background-clip: border-box;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  /* box-shadow: 2px 2px 2px 0px #626d6e; */
  display: inline-block;
  transition: ease-in-out 0.3s;
  border: 1px solid rgb(255, 255, 255);
}

.pms-white-button:hover {
  color: #fff;
  background-color: #0aa7b8;

}

/* ---------------------------------------Modal Input----------------------------- */

.modal-label-name {
  color: rgba(54, 54, 54, 0.85);
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0px;
  word-spacing: 1px;
  /* box-shadow: none; */
}

.modal-input-field {
  height: 32px;
  font-size: 14px;
  border-color: #c1c1c1;
  border-width: 1px;
  border-radius: 4px;
  padding: 0 5px;
}

textarea:focus,
input:focus {
  /* outline-color: #0AA7B8; */
  outline: none;
}

select:focus {
  outline-color: #0AA7B8;
}

/* ---------------------------------------Modal Box----------------------------- */

.box {
  border-top: 10px solid #0AA7B8;
  border-bottom: 10px solid #0AA7B8;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 0px #737676;
}

/* ---------------------------------------Single Calender----------------------------- */

.single-date {
  border-radius: 5px;
  border-width: 1px;
  border-color: #c1c1c1;
  /* box-shadow: 2px 2px 2px 0px #8d8e8e; */
}

/* -----------------------------------------input form --------------------------------------- */

.input-border {
  border-bottom: 1px solid #09a2b37f;
  font-size: 14px;
  color: #4b5563;
  border-radius: 0;
  font-weight: 500;
  /* margin-left: 4px; */
}

.label-font {
  /* font-size: 15px;
  color: #9b9b9b; */
  font-size: 13px;
  color: #00a1b1;
  font-weight: 500;
  text-align: left;
  font-family: poppins,
    sans-serif;
}

.label-font-tabbing {
  font-size: 15px;
  color: #00a1b1;
  font-weight: 500;
  text-align: left;
  font-family: poppins, sans-serif;
}


/* ---------------------------- email setting --------------- */

.email-setting {
  line-height: 27.5px !important;
  letter-spacing: 0.4px;
  word-spacing: 0.4px;
}

/* ------------------------------- navigation -------------------- */

.nav-box {
  /* box-shadow: 8px 8px 10px -3px rgba(71, 71, 71, 0.85); */
  /* filter: drop-shadow(8px 8px 4px hsla(180, 1%, 16%, 0.514)); */
}