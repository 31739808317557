.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    position: relative;
    color: #fff;
    font-weight: 600;
    text-align: start;
    background: #00a1b1;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: #007d88;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 8px 8px;
    text-align: center;
    /* Below 3 lines added */
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th,
.ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 1px 8px;
}

.ant-table-wrapper .ant-table-cell-ellipsis .ant-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    font-size: 12px;
    /* font-family: 'Roboto',
        sans-serif; */
    font-family: poppins, sans-serif;
    letter-spacing: 1px;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table-header>table>thead>tr>th,
.ant-table-body>table>thead>tr>th,
.ant-table-summary>table>thead>tr>th {
    border-inline-end: 1px solid #f0f0f0;
    font-size: 12px;
    /* font-family: 'Roboto',
        sans-serif; */
    font-family: poppins, sans-serif;
    letter-spacing: 1px;
}

.anticon-plus {
    display: none;
}


.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #007d88;
}

a:hover {
    color: #00a1b1;
}

.ant-table-wrapper .ant-table-filter-trigger {
    color: rgba(255, 255, 255, 0.994);
}

.ant-table-wrapper .ant-table-column-sorter {
    color: rgba(248, 248, 248, 0.983);
}

.ant-table-wrapper .ant-table-column-sorter-down.active {
    color: #ffffff;
}

.ant-table-wrapper .ant-table-column-sorter-up.active,
.ant-table-wrapper .ant-table-column-sorter-down.active {
    color: #ffffff;
}

.ant-btn-primary {
    color: #fff;
    background-color: #0aa7b8;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-btn-primary:not(:disabled):hover {
    color: #fff;
    background-color: #309bab;
}

.ant-btn-link {
    color: #0aa7b8;
}

.ant-table-wrapper .ant-table-filter-trigger.active {
    color: rgba(39, 50, 51, 0.87);
}


/*-------------- TOGGLE ---------------*/

.ant-switch.ant-switch-checked {
    background: #00a1b1;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #0aa7b8;
}

.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-inner {
    padding-inline-start: 6px;
    padding-inline-end: 18px;
    background-color: #0AA7B8;
}

.ant-switch.ant-switch-small .ant-switch-inner {
    padding-inline-start: 18px;
    padding-inline-end: 6px;
    background-color: rgb(144, 144, 144);
}

/*-------------- Modal ---------------*/

.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 0px !important;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 0px 0px !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover {
    color: #0AA7B8;
}

/*-------------- CheckBox ---------------*/

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0AA7B8;
    border-color: #0AA7B8;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #0AA7B8;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    top: 50%;
    inset-inline-start: 50%;
    width: 8px;
    height: 8px;
    background-color: #0aa7b8;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: "";
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #0aa7b8;
}

/*----------------date picker------------------*/


.ant-picker-focused {
    border-color: #0aa7b8;
    box-shadow: none;
    border-inline-end-width: 1px;
    outline: 0;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #0aa7b8;
    padding-top: 2px;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    transition: background 0.2s, border 0.2s;
    padding-top: 2px;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #0aa7b8;
    border-radius: 4px;
    content: "";
}

.ant-picker-dropdown .ant-picker-today-btn {
    color: #0aa7b8;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: #0aa7b8;
    border-inline-end-width: 1px;
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    padding: 4px 0;
}

.ant-picker-dropdown .ant-picker-today-btn:hover {
    color: #0AA7B8;
}

.ant-picker-dropdown .ant-picker-header {
    display: flex;
    padding: 4px 8px;
    color: rgba(52, 52, 52, 0.88);
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

/* tabes -------------------------------
 */

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin: 0px 0 0 0;


}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
    padding: 8px 24px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #000;
}

.ant-tabs .ant-tabs-tab:hover {
    color: #fff;
    background-color: #4096ff;
}