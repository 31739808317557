@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'Poppins',
 sans-serif; */
  font-family: 'Roboto', sans-serif !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.fullscreen {
  overflow: auto;
  /* background: #edf6f7; */
  background: #edf6f7;
}

/* down arrow animation */
.arrow {
  color: white;
  font-size: 18px;

}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}


/* Customized default Select Option */
select option:checked {
  background: #32A2B3;
  color: #fff;
}

select option {
  padding: 4px;
  font-family: sans-serif;
}

select option:hover {
  background: #32A2B3 !important;
  color: #fff;
}

/* For copying things */
::selection {
  color: white !important;
  background: #32A2B3 !important;
}

/* -----------------------------------------input form --------------------------------------- */



@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(11px);
  }

  60% {
    transform: translateY(11px);
  }
}

/* end */

.rs-calendar-table-cell {
  display: table-cell;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
  width: 1%;
}

.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
  position: relative;
}




.rs-nav-justified {
  display: -ms-flexbox;
  display: flex;
}

.rs-nav-horizontal {
  white-space: nowrap;
}

.rs-nav {
  position: relative;
  outline: 0;
}



.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  border-bottom-width: 0;

}

.rs-nav-tabs.rs-nav-horizontal>.rs-nav-item,
.rs-nav-tabs.rs-nav-horizontal>.rs-dropdown .rs-dropdown-toggle {
  border-radius: 6px 6px 0 0;
}

.rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-navs-tab-border);
  background-color: #fff;
  background-color: var(--rs-bg-card);
  z-index: 1;
}

.rs-nav-justified>.rs-nav-item,
.rs-nav-justified>.rs-dropdown {
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
}

.rs-nav-horizontal>.rs-nav-item,
.rs-nav-horizontal>.rs-dropdown {
  display: inline-block;
  vertical-align: top;
}

/* active color chnage */
.rs-nav-item.rs-nav-item-active {
  position: relative;
  color: #089bab;
  z-index: 1;
}

/* responsive */
.rs-nav-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #8e8e93;
  color: var(--rs-navs-text);
  -webkit-transition: color 0.3s linear, background-color 0.3s linear;
  transition: color 0.3s linear, background-color 0.3s linear;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  text-decoration: none;
}

.rs-nav :hover {
  border-right: 1px solid;
  border-left: 1px solid;
  border-top: 1px solid;
  color: 2px solid #089bab;

}

.zfixed {
  z-index: 1;
}


@media only screen and (max-width: 600px) {
  .rs-nav-justified {
    display: flex;
    flex-direction: column;
  }

  a.rs-nav-item {
    margin: 13px 0px 1px -1px;
  }
}

/* scrolling  */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  /* background-color: #fff; */
  opacity: 0.1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

::-webkit-scrollbar-thumb {
  /* box-shadow: 0px 0px 2px 0.5px #004d54; */
  background-color: #00a3b2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c2c2c2;

}

.rmsc .dropdown-content {
  position: absolute !important;
  z-index: 13000 !important;
  top: 100% !important;
  width: 100% !important;
  padding-top: 8px !important;
}

.widthfix {
  min-width: 100% !important;
}

.rmsc .item-renderer {
  display: flex;
  align-items: center !important;
}


/* phone component customize */

.PatientinformationInput.react-tel-input .form-control {
  border: none;
  border-bottom: 1px solid #00a3b2;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  padding: 1px 0px 0 22px !important;
}

.PatientinformationInput.react-tel-input .form-control:focus {
  box-shadow: 0 0 0 1px #fafafa;

}

.ModalPhoneInput .react-tel-input .form-control {
  width: 100%;
  height: 32px;
}

.ModalPhoneInput .react-tel-input .form-control:focus {
  box-shadow: 0 0 0 1px #00a3b2;
}





.loader-line {
  /* width: 200px; */
  height: 7px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  /* margin: 100px auto; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #00a3b2;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

input[name="gender"] {
  accent-color: teal;
}

/* button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
} */

button[disabled],
html input[disabled] {
  cursor: not-allowed;
}


/* calandar
 */


.tooltipevent {
  position: absolute;
  width: 300px;
  height: auto;
  background: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
  z-index: 10001;
  border-radius: 10px;
  font-size: 12px;
  font-family: poppins, sans-serif;
  cursor: pointer;
}

.individual-div {
  display: flex !important;
  align-items: center !important;
}

.tooltip-container {
  /* Additional styles for the container */
}

.tooltip-content {
  /* Additional styles for the content */
}