:root {
  --blue: #1E88E5;
  --yellow: #FBC02D;
  --green: #4CAF50;
}

/* override day number color and size  */
.fc-day-number {
  font-size: 1.5em;
  color: #5c005c;
}

/* / override day title color and size /  */
.fc-day-header {
  font-size: 1.5em;
  color: #00b294;
}

/* / / Header Button / /  */
.fc .fc-button-group button,
.fc .fc-button-group .fc-button-active,
.fc .fc-button-primary {
  border-color: #fff !important;
  text-transform: capitalize !important;
}

.fc .fc-button-primary:disabled {
  border-color: #fff !important;
  text-transform: capitalize;
}

.fc-next-button,
.fc-prev-button,
.fc-timeGridWeek-button {
  background: var(--green) !important;
}

.fc-prevYear-button,
.fc-nextYear-button,
.fc-dayGridMonth-button {
  background: var(--blue) !important;
}

.fc-today-button {
  background: #0AA6B7 !important;
}

.fc-timeGridDay-button {
  background: var(--yellow) !important;
}

.fc-toolbar-title {
  color: #495057;
}

.fc .fc-col-header-cell-cushion {
  color: #74788D;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  color: black;
}

.fc-v-event .fc-event-title {
  color: black;
}

.fc-timegrid-event .fc-event-time {
  color: black;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .fc .fc-toolbar {
    display: inline-block;
    float: none !important;

  }

  .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin-left: 0px;
  }
}

/* for hovering */
.event-wrapper {
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: black;
  color: red;
  white-space: nowrap;
  z-index: 1;
}

.event:hover .tooltip {
  display: block;
}

.card {
  top: calc(var(--fc-event-top) - 20px);
  left: var(--fc-event-left);
}

.tooltip-custom {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  font-size: 13px;
  font-family: poppins, sans-serif;
}

/* event text color */
.fc-daygrid-block-event .fc-event-time {
  color: black;
}

.fc-daygrid-block-event .fc-event-title {
  color: white;
}

.fc-event {
  /* padding: 5px; */
  cursor: pointer;
  /* Adjust the height as needed */
}


/* -----------------------------calender green sheed css----------------------- */

.calendar-tooltip-container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  /* width: 25%; */
  padding: 8px;
  border-radius: 5px;
  width: 280px;
  font-size: 11px !important;
  /* height:340px; */
  background: #fff;
  position: absolute;
  z-index: 10001;
  padding: 14px;
}

.calendar-tooltip-container::before {
  content: "";
  width: 100%;
  height: 5px;
  background: #089bab;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.calendar-tooltip-container::after {
  content: "";
  width: 100%;
  height: 5px;
  background: #089bab;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}