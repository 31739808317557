.listview .dropdown-container {
    border-bottom: 2px solid rgb(251, 251, 251) !important;
    width: 150px;
    background-color: #00a1b100 !important;
}

.listview {
    border-bottom: 2px solid #fff !important;
}

/*  */

.listview .dropdown-container {
    margin: -5px 0px 0px 0px;
    background-color: #00a1b100 !important;
    border: 0px solid #00a1b100 !important;
    width: 100% !important;
    border-radius: 0px !important;
    border: 0px solid var(--rmsc-border) !important;
}

.listview .dropdown-container:focus-within {
    box-shadow: #00a1b100 0 0 0 0px !important;
}

.listview .gray {
    color: #fff !important;
}

.listview .dropdown-heading .dropdown-heading-value {
    color: #fff !important;
}

.rmsc .dropdown-content .panel-content {
    overflow: hidden;
    border-radius: var(--rmsc-radius);
    background: var(--rmsc-bg);
    box-shadow: 0 0 0 1px #0000001a, 0 4px 11px #0000001a;
    z-index: auto !important;
}

.listview .dropdown-content {
    padding-top: 8px !important;
    position: absolute !important;
    top: 100% !important;
    width: 200px !important;
    z-index: 13000 !important;
}

.listview .dropdown-heading {
    width: 200px !important;
    height: 30px !important;
}