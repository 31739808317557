/* React-paginate package */

.pagination {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    cursor: pointer;
    font-size: 14px;
    gap: 7px;
    font-weight: 500;
    color: #808080;
}

.pagination a {
    padding: 6px 9px;
    border: 1px solid white;
    border-radius: 7%;
    border: 1px solid #309BAB;
    color: #000;
    box-shadow: 1px 1px 2px 1px #a3a3a3;
    border: 1px solid #a3a3a3;
}

.pagination_Link {
    font-weight: 300;
}

.pagination_Link-active a {
    color: white;
    border: 1px solid #309BAB;
    background-color: #309BAB;
}

.pagination a:hover {
    border: 1px solid #309BAB;
    box-shadow: 1px 1px 2px 1px #808080;
    background-color: #309BAB;
    color: white;
}

.pagination_Link {
    font-weight: 500;
    margin-bottom: 5px;
}

.pagination_Link-disabled a {
    color: white;
    background-color: #dedfdf;

}

/* .pagination_Link-disabled a:hover {
    border: 1px solid #000;

} */

@media only screen and (max-width:320px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    /* For mobile  */

    .pagination a {
        padding: 2px 5px;
        border: 1px solid white;
        border-radius: 7%;
        border: 1px solid #309BAB;
        color: #000;
        box-shadow: 1px 1px 2px 1px #a3a3a3;
        border: 1px solid #a3a3a3;
    }

    .pagination_Link-active a {
        color: white;
        border: 1px solid #309BAB;
        background-color: #309BAB;
    }
}

@media only screen and (max-width:481px) {

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /* For mobile  */

    .pagination a {
        padding: 2px 5px;
        border: 1px solid white;
        border-radius: 7%;
        border: 1px solid #309BAB;
        color: #000;
        box-shadow: 1px 1px 2px 1px #a3a3a3;
        border: 1px solid #a3a3a3;
    }

    .pagination_Link-active a {
        color: white;
        border: 1px solid #309BAB;
        background-color: #309BAB;
    }
}