/* .jahid .dropdown-container {
  border: 2px solid red !important;
} */

.AppoinmentGlobal .dropdown-container {
  max-width: 200px !important;
  min-width: 220px !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.AppoinmentGlobal .dropdown-container:focus-within {
  /* box-shadow: #aa0606 0 0 0 1px !important; */
  /* border-color: var(--rmsc-main) !important; */
}

.AppoinmentGlobal .dropdown-container:focus-within {
  /* box-shadow: #80bdff 0 0 0 3px; */
  /* border-color: var(--rmsc-main); */
  /* background-color: brown; */
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(71 92 199 / 25%) !important;
}

.AppoinmentGlobal .dropdown-content .panel-content {
  max-height: 11rem !important;

}


.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 30px;
}

.jakir .dropdown-heading {
  height: var(--rmsc-h) !important;
  /* height: calc(1.5em + 0.5rem + 2px) !important; */
  /* padding: 1rem !important; */
  /* padding: 5px 10px !important;
  height: 30px !important; */
}

.parentSelection {
  display: flex;
  justify-content: space-between;
}

.btnCustom {
  margin: 0 5px;
}