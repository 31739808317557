.rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
    border-top: 2px solid #e4e4f0 !important;
   
}

.rs-nav-justified > .rs-nav-item, .rs-nav-justified > .rs-dropdown {
    /* -ms-flex: 1 1 1%; */
    flex: 0 1 20% !important ;
}

/* border color */
.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom-width: 0;
    border-top-color: rgb(8 155 171) !important;
    border-top-width: 4px !important;
    border-bottom-width: 0;
    border-top-color: rgb(8 155 171) !important;
    border-top-width: 4px !important;
    border-right: 1px solid;
    border-left: 1px solid;
    border-top: 1px solid;
    border-color: #dee2e6;
    color: rgb(8 155 171);
}
.badge {
    text-transform: capitalize;
    background: #09aab9;
    /* box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px; */
    box-shadow: rgb(9 169 185 / 80%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
}
