.form-head {
    font-size: 17px;
    line-height: 25.5px;
    color: #207ac7;
    font-weight: 600;
}

.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    min-height: 250px;
    max-height: 300px;
    background-color: #fff;
}

.form-inner-head {
    font-size: 16px;
    line-height: 30.5px;
    color: #207ac7;
    font-weight: 600;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    word-spacing: 0.2px;
}

.form-sub-header {
    margin: 20px 0;
    font-size: 1.5rem;
    line-height: 38.4px;
    color: #d9534f;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
}

.form-border {
    border: 15px solid #207ac7;
}

.form-title {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #d9534f;
    font-weight: 600;
    text-transform: uppercase;

}

.form-small-title {
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #d9534f;
    font-weight: 600;
    text-transform: uppercase;

}

.form-input-name-black {
    font-size: 17px;
    line-height: 22.6px;
    color: #000;
    font-weight: 500;
}

.form-input-border {
    border: 2px solid #207ac7;
    border-left-width: 1px;
    padding: 5px;
    font-size: 17px;
}

.form-input-textarea {
    border: 2px solid #207ac7;
    border-left-width: 1px;
    padding: 10px;
    font-size: 12px;
}

.form-input-textarea-borderNone {
    border: none;
    border-left-width: 1px;
    padding: 10px;
    font-size: 12px;
}

.form-select-border {
    border: 2px solid #207ac7;
    border-left-width: 1px;
    padding: 11px;
    font-size: 17px;
}

.form-select-border-bottom {
    border-bottom: 2px solid #207ac7;
    border-left-width: 1px;
    font-size: 14px;
}

.form-input-border-date {
    border: 2px solid #207ac7;
    border-left-width: 1px;
    padding: 11px;
    font-size: 17px;
}

.-black {
    color: #207ac7;
    font-size: 17px;
    line-height: 25.5px;
    font-weight: 700;
    margin-right: 10px;
    letter-spacing: 1px;
    word-spacing: 0.2px;
}

.form-input-list-name {
    font-size: 17px;
    line-height: 25.5px;
    font-weight: 700;
    letter-spacing: 1px;
    word-spacing: 0.2px;

}

.form-bottom-border {
    border-bottom: 1px solid #207ac7;
    font-size: 16px;
    border-radius: 0.125rem;
    font-weight: 500;
    margin-left: 4px;
}

.form-footer {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    color: #207ac7;
    word-spacing: 0.2px;
    line-height: 13.6px !important;
    font-family: 'Roboto', sans-serif;
    padding: 25px 10px;
    border-color: #777777;
    box-shadow: 0px -2px 3px -1px #aaaaaa;
}

.form-textarea {
    margin: 15px 0px 0px;
}

.form-textarea>textarea {
    width: 100%;
    border: 2px solid #207ac7;
    padding: 10px;
    font-size: 16px;
}