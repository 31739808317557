.custom-border {
    border: 1px solid red;
}

.patient-nav {
    border: 1px solid #09A2B3;
    border-radius: 2px;
}

.active-nav {
    background-color: aqua;
}



.clink:hover {
    background-color: #e9f0f1d3;
    border-radius: 2px;
    box-shadow: 0px 1px 2px -1px #2b2b2b;
}